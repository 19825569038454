import {FC} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {User} from '../../../../app/modules/auth/models/UserModel'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {RootState} from '../../../../setup'

const HeaderSelectClinic: FC = () => {
    const dispatch = useDispatch()

    const user: User = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as User

    function handleSelectClinic(clinic_id: number) {
        dispatch(auth.actions.setClinic(clinic_id))
        document.location.reload()
    }

    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
            data-kt-menu='true'
        >
            <ul
                className='list-group px-2 overflow-auto'
                style={{
                    maxHeight: '400px',
                }}
            >
                {user.clinics.map((clinic) => (
                    <li key={clinic.id} className='list-group-item border-0 menu-item'>
                        <button
                            className='btn btn-sm btn-active-primary w-100 text-start'
                            onClick={() => handleSelectClinic(clinic.id)}
                        >
                            {clinic.nm_fantasy}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export {HeaderSelectClinic}
