import {useCallback, useEffect, useState} from 'react'
import {SearchSelect, TextInput} from '../../../../modules/Form'
import {getExternalBanks} from '../../../../shared/statics'
import {BankModel} from '../../../../shared/models'

interface Props {
    setField: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}
export default function BankRegisterClinic({setField}: Props) {
    const [banks, setBanks] = useState<BankModel[]>([])

    const initData = useCallback(async () => {
        if (banks.length === 0) {
            const apiBanks = await getExternalBanks()
            setBanks(apiBanks)
        }
    }, [banks.length])

    useEffect(() => {
        initData()
    }, [initData])

    const selectBanks = banks.map((bank) => {
        return {value: bank.code, label: bank.ds_bank}
    })

    function handleChange(event: any) {
        setField('nm_bank', event.label)
        setField('vn_bank', event.value)
    }
    return (
        <div className='card'>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-12 mb-5'>
                        <SearchSelect
                            name='bank'
                            label='Banco'
                            onChange={(e) => handleChange(e)}
                            options={selectBanks}
                            required
                        />
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col-12 col-md-2'>
                        <TextInput
                            label='Agência'
                            name='vn_agency'
                            placeholder='Agência'
                            type='number'
                            min='0'
                            required
                        />
                    </div>
                    <div className='col-12 col-md-3'>
                        <TextInput
                            label='Nº da conta'
                            name='vn_account'
                            type='number'
                            min='0'
                            placeholder='Número'
                            required
                        />
                    </div>
                    <div className='col-12 col-md-2'>
                        <TextInput
                            label='Dígito'
                            name='vn_digit'
                            placeholder='X'
                            maxLength={1}
                            required
                        />
                    </div>
                    <div className='col-12 col-md-5'>
                        <TextInput
                            label='Chave PIX'
                            name='cd_pix_key'
                            placeholder='Entre com a chave'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
