import axios from "axios";
import { BankModel } from "../models";

export async function getExternalBanks(): Promise<BankModel[]> {
    try {
      const { data } = await axios.get('/functions/get-banks');
      return data.data.select; 
    } catch (error) {
      return []; 
    }
}
