import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export enum ManagementsRouterEnum {
    /*Rotas para Acessos > Usuários*/
    DatatableUsers = '/gestao/acessos/usuarios',
    ProfileUser = '/gestao/acessos/usuarios/:id',
    /*Rotas para Acessos > Perfis*/
    DatatablePrivilege = '/gestao/acessos/perfis',
    StorePrivilege = '/gestao/acessos/perfis/cadastrar',
    UpdatePrivilege = '/gestao/acessos/perfis/:id/atualizar',
    /*Rotas para Clientes*/
    DatatableClient = '/gestao/clientes',
    ProfileClient = '/gestao/clientes/:id',
    /*Rotas para Avaliações*/
    DatatableRating = '/gestao/avaliacoes',
}

export default function ManagementsRouter() {
    /*Rotas para Acessos > Usuários*/
    const DatatableUsers = lazy(() => import('../../pages/management/users/DatatableUsers'))
    const ProfileUser = lazy(() => import('../../pages/management/users/ProfileUser'))

    /*Rotas para Acessos > Perfis*/
    const DatatablePrivileges = lazy(
        () => import('../../pages/management/access/privilege/DatatablePrivilege')
    )
    const StorePrivilege = lazy(
        () => import('../../pages/management/access/privilege/StorePrivilege')
    )
    const UpdatePrivilege = lazy(
        () => import('../../pages/management/access/privilege/UpdatePrivilege')
    )

    /*Rotas para Clientes*/
    const DatatableClient = lazy(() => import('../../pages/management/clients/DatatableClient'))
    const ProfileClient = lazy(() => import('../../pages/management/clients/ProfileClient'))

    /*Rotas para Avaliações*/
    const DatatableRating = lazy(
        () => import('../../pages/management/clients/rating/DatatableRating')
    )
    return (
        <Switch>
            {/*Rotas para Usuários*/}
            <Route path={ManagementsRouterEnum.ProfileUser} component={ProfileUser} />
            <Route path={ManagementsRouterEnum.DatatableUsers} component={DatatableUsers} />

            {/*Rotas para Perfis*/}
            <Route path={ManagementsRouterEnum.UpdatePrivilege} component={UpdatePrivilege} />
            <Route path={ManagementsRouterEnum.StorePrivilege} component={StorePrivilege} />
            <Route
                path={ManagementsRouterEnum.DatatablePrivilege}
                component={DatatablePrivileges}
            />

            <Route path={ManagementsRouterEnum.ProfileClient} component={ProfileClient} />
            <Route path={ManagementsRouterEnum.DatatableClient} component={DatatableClient} />

            <Route path={ManagementsRouterEnum.DatatableRating} component={DatatableRating} />
        </Switch>
    )
}
