import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export enum BillingRouterEnum {
    /*Rotas para Conta-Corrente*/
    InvoiceClinic = '/financeiro/faturamento/estabelecimento',
    InvoiceClinicDetail = '/financeiro/faturamento/:id/estabelecimento',
    InvoiceDoctor = '/financeiro/faturamento/profissional',
    InvoiceDoctorDetail = '/financeiro/faturamento/:id/profissional/:doctorId',
}

export default function BillingRouter() {
    const ApprovedNotes = lazy(() => import('../../pages/billing/fiscalNotes/ApprovedNotes'))
    const PendingNotes = lazy(() => import('../../pages/billing/fiscalNotes/PendingNotes'))

    /*Rotas para Conta-Corrente*/
    const InvoiceClinic = lazy(
        () => import('../../pages/billing/invoices/clinic/DatatableClinicInvoice')
    )
    const InvoiceClinicDetail = lazy(
        () => import('../../pages/billing/invoices/clinic/ShowClinicInvoice')
    )
    const InvoiceDoctor = lazy(
        () => import('../../pages/billing/invoices/doctor/DatatableDoctorInvoice')
    )
    const InvoiceDoctorDetail = lazy(
        () => import('../../pages/billing/invoices/doctor/ShowDoctorInvoice')
    )

    return (
        <Switch>
            <Route path='/financeiro/notas-fiscais/aprovadas' component={ApprovedNotes} />
            <Route path='/financeiro/notas-fiscais/pendentes' component={PendingNotes} />

            {/*Rotas para Conta-Corrente*/}
            <Route path={BillingRouterEnum.InvoiceClinicDetail} component={InvoiceClinicDetail} />
            <Route path={BillingRouterEnum.InvoiceClinic} component={InvoiceClinic} />
            <Route path={BillingRouterEnum.InvoiceDoctor} component={InvoiceDoctor} />
            <Route path={BillingRouterEnum.InvoiceDoctorDetail} component={InvoiceDoctorDetail} />
        </Switch>
    )
}
