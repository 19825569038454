import {CustomCardHeaderTitle} from '../../../_metronic/layout/components/cards/CustomCardHeaderTitle'
import {ButtonGoBack} from './ButtonGoBack'

interface Props {
    title: string
    children?: React.ReactNode
    goBack?: boolean
    description?: string
}
export function CardHeader({title, children, goBack = true, description = ''}: Props) {
    return (
        <div className='card-header'>
            <CustomCardHeaderTitle cardTitle={title} cardDescription={description} />
            <div className='card-toolbar'>
                {children}
                {goBack && <ButtonGoBack />}
            </div>
        </div>
    )
}
