import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export enum ProviderRouterEnum {
    DatatableDoctors = '/prestadores/profissionais',
    RegisterDoctor = '/prestadores/profissionais/cadastro',
    ProfileDoctors = '/prestadores/profissionais/:id',
}

export function ProviderRouter() {
    /*Profissionais*/
    const DatatableDoctors = lazy(() => import('../../pages/providers/doctors/DatatableDoctors'))
    const RegisterDoctor = lazy(() => import('../../pages/providers/doctors/RegisterDoctor'))
    const ProfileDoctors = lazy(() => import('../../pages/providers/doctors/ProfileDoctor'))

    /*Serviços*/
    // const Services = lazy(() => import('../../pages/providers/clinics/components/ServicesClinic'))

    return (
        <Switch>
            {/* Profissionais */}
            <Route path={ProviderRouterEnum.RegisterDoctor} component={RegisterDoctor} />
            <Route path={ProviderRouterEnum.ProfileDoctors} component={ProfileDoctors} />
            <Route path={ProviderRouterEnum.DatatableDoctors} component={DatatableDoctors} />
        </Switch>
    )
}
