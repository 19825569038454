import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    cardTitle: String
    cardDescription: String
}

export function CustomCardHeaderTitle({cardTitle, cardDescription}: Props) {
    return (
        <div className='card-title align-items-start flex-column'>
            <span className='card-label'>
                {cardTitle}
                {cardDescription && (
                    <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={<Tooltip id='tooltipCard'>{cardDescription}</Tooltip>}
                    >
                        <button
                            type='button'
                            className='ms-2 btn btn-sm btn-icon btn-light-primary'
                        >
                            <i className='fas fa-info'></i>
                        </button>
                    </OverlayTrigger>
                )}
            </span>
        </div>
    )
}
